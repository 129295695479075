@import '../../style/themes/index';
@import '../../style/mixins/index';

@breadcrumb-prefix-cls: ~'@{ant-prefix}-breadcrumb';

.@{breadcrumb-prefix-cls} {
  .reset-component;

  color: @breadcrumb-base-color;
  font-size: @breadcrumb-font-size;

  .@{iconfont-css-prefix} {
    font-size: @breadcrumb-icon-font-size;
  }

  a {
    color: @breadcrumb-link-color;
    transition: color 0.3s;
    &:hover {
      color: @breadcrumb-link-color-hover;
    }
  }

  & > span:last-child {
    color: @breadcrumb-last-item-color;
    a {
      color: @breadcrumb-last-item-color;
    }
  }

  & > span:last-child &-separator {
    display: none;
  }

  &-separator {
    margin: @breadcrumb-separator-margin;
    color: @breadcrumb-separator-color;
  }

  &-link {
    > .@{iconfont-css-prefix} + span {
      margin-left: 4px;
    }
  }

  &-overlay-link {
    > .@{iconfont-css-prefix} {
      margin-left: 4px;
    }
  }
}

@import './rtl';

@primary-color: #1aa3c5;@height-base: 40px;@height-lg: 46px;@height-sm: 32px;@font-size-base: 16px;@font-size-lg: @font-size-base + 4px;@font-size-sm: 12px;@form-vertical-label-margin: 10px 0;